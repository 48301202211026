import type {CheckoutContext} from 'Types/InvestorFlow/checkoutContext';
import {ApiTarget} from 'Types/api';
import api from 'Utility/api';
import {loadFeatureFlags} from 'Utility/featureFlags';
import {get, writable} from 'svelte/store';

import {
  dealMakerUrl as dealMakerURL,
  apiUrl as apiURL,
  hostUrl,
} from 'Store/embedded/embeddedFlowStore';
import {
  dealMakerUrl as investorStoreDealMakerUrl,
  apiUrl as investorStoreApiUrl,
} from 'Store/InvestorFlowStore';

export const environmentVariables = writable<{[key: string]: string}>({});

type Variables = {
  SVELTE_APP_API_HOST: string;
  SVELTE_APP_STRIPE_PUBLIC_KEY: string;
  SVELTE_APP_AMPLITUDE_CLIENT_KEY: string;
  email_host: string;
  SVELTE_APP_DATADOG_RUM_APPLICATION_ID: string;
  SVELTE_APP_DATADOG_RUM_CLIENT_TOKEN: string;
  SVELTE_APP_DATADOG_TOKEN: string;
  STATIC_PAGE_TERMS_AND_CONDITIONS: string;
  PUBLIC_DOMAINS: string;
  DROPBOX_SIGN_SKIP_DOMAIN_VERIFICATION: string;
  recaptcha_threshold: string;
  GOOGLE_GEOCODER_API_KEY: string;
};

type AppSettingsResponse = {
  variables: Variables;
};

export const loadAppSettings = async (): Promise<void> => {
  const variables = await getAppSettings();
  environmentVariables.set(variables);
  setupStores(get(hostUrl));

  await loadFeatureFlags([
    'ownerless_profiles',
    'enable_ttw',
    'new_platform_emails',
    '506c_improvements',
  ]);
};

const getAppSettings = async (): Promise<Variables> => {
  const response = await api.getJson<AppSettingsResponse>(
    ApiTarget.V1,
    'app_settings',
    null,
    {
      anonymous: true,
    }
  );
  return response.variables;
};

const getCheckoutContext = async (): Promise<CheckoutContext> => {
  const response = await api.getJson<CheckoutContext>(
    ApiTarget.V1,
    'embedded/checkout_context'
  );

  return response;
};

const setupStores = (application_url: string) => {
  const apiUrl = new URL('/api', application_url);
  const dealMakerUrl: URL = new URL(application_url);
  apiURL.set(apiUrl);
  investorStoreApiUrl.set(apiUrl);
  dealMakerURL.set(dealMakerUrl);
  investorStoreDealMakerUrl.set(dealMakerUrl);
};

export const getStaticAssetsUrl = (asset_name: string): string => {
  return `${get(apiURL).href}/assets/${asset_name}`;
};
